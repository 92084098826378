/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Link from 'Component/Link';
import { ACCOUNT_ORDER_PRINT_URL } from 'Route/MyAccount/MyAccount.config';
import {
    MyAccountOrder as SourceMyAccountOrder
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.component';
import { convertStringToDate } from 'Util/Manipulations/Date';
import { appendWithStoreCode } from 'Util/Url';

import {
    ORDER_ACTION_LABELS
} from './MyAccountOrder.config';

import './MyAccountOrder.override.style';

/** @namespace Satisfly/Component/MyAccountOrder/Component */
export class MyAccountOrder extends SourceMyAccountOrder {
    renderSubscriptionButton() {
        const { order: { rss_link }, rss_order_subscribe_allow } = this.props;

        if (!rss_order_subscribe_allow) {
            return null;
        }

        return (
            <Link
              block="MyAccountOrder"
              elem="SubscribeToStatus"
              to={ rss_link }
              isOpenInNewTab
              aria-label={ __('Subscribe to Order Status') }
            >
                { __('Subscribe to Order Status') }
            </Link>
        );
    }

    renderReorderButton() {
        const { is_allowed_reorder, handleReorder, order: { can_reorder } } = this.props;

        if (!is_allowed_reorder || !can_reorder) {
            return null;
        }

        return (
            <button
              block="Button"
              mods={ { isHollow: true, medium: true } }
              mix={ { block: 'MyAccountOrder', elem: 'Reorder' } }
              onClick={ handleReorder }
            >
                { __('Reorder') }
            </button>
        );
    }

    renderPrintOrder() {
        const { order: { id } } = this.props;

        return (
            <button
              block="Button"
              mods={ { likeLink: true } }
            >
                <Link
                  block="MyAccountOrder"
                  elem="SubscribeToStatus"
                  to={ appendWithStoreCode(`${ACCOUNT_ORDER_PRINT_URL}/${id}`) }
                  isOpenInNewTab
                  aria-label={ __('Print Order') }
                >
                    { __('Print') }
                </Link>
            </button>
        );
    }

    renderPrintAllAction() {
        const { activeTab, order: { id } } = this.props;

        const { printAllUrl, printAll } = ORDER_ACTION_LABELS[activeTab] || {};

        if (!printAllUrl) {
            return null;
        }

        return (
            <Link
              block="MyAccountOrder"
              elem="PrintOrder"
              to={ appendWithStoreCode(`${printAllUrl}/${id}`) }
              isOpenInNewTab
              aria-label={ printAll }
            >
                { printAll }
            </Link>
        );
    }

    renderBaseInfo() {
        const { order: { order_date } } = this.props;

        return (
            <div block="MyAccountOrder" elem="CreationDate">
                <span>{ convertStringToDate(order_date) }</span>
            </div>
        );
    }
}

export default MyAccountOrder;
