/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { getBlogTitle } from '../../util/Blog';
import BlogPostCard from '../BlogPostCard';
import { MAX_RECENT_POSTS } from './BlogRecentPosts.config';

import './BlogRecentPosts.style';

/** @namespace Satisfly/MagefanBlog/Component/BlogRecentPosts/Component */
export class BlogRecentPostsComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        posts: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        dynamicTitle: PropTypes.string,
        isSidebar: PropTypes.bool
    };

    static defaultProps = {
        dynamicTitle: '',
        isSidebar: false
    };

    renderGrid() {
        const {
            isLoaded,
            posts
        } = this.props;

        if (isLoaded && posts?.length) {
            return posts.map((post) => <BlogPostCard key={ post.post_id } block="BlogPostCard" post={ post } />);
        }

        // eslint-disable-next-line max-len
        return Array.from({ length: MAX_RECENT_POSTS }, (_, i) => <BlogPostCard key={ i } block="BlogPostCard" isPlaceholder />);
    }

    render() {
        const { dynamicTitle, isSidebar } = this.props;
        const title = getBlogTitle();

        return (
            <div block="BlogRecentPosts" elem="Wrapper">

                { /* eslint-disable */
                    isSidebar ? (
                        <h1 block="BlogRecentPosts" elem="Title">
                            { dynamicTitle || title }
                        </h1>
                    ) : (

                        <h3 block="BlogRecentPosts" elem="Title">
                            { dynamicTitle || title }
                        </h3>
                    ) 
                    /* eslint-enable */ }
                <div block="BlogRecentPosts" elem="Grid">
                    { this.renderGrid() }
                </div>
            </div>
        );
    }
}

export default BlogRecentPostsComponent;
