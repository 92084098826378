/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    CloseIcon as SourceCloseIcon
} from 'SourceComponent/CloseIcon/CloseIcon.component';

/** @namespace Satisfly/Component/CloseIcon/Component */
export class CloseIcon extends SourceCloseIcon {
    render() {
        return (
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8.5L8 16.5" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 8.5L16 16.5" stroke="#4B5563" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        );
    }
}

export default CloseIcon;
