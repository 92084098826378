/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const RECEIPT_TYPE = {
    id: 1,
    title: __('Receipt')
};
export const INVOICE_TYPE = {
    id: 2,
    title: __('Invoice')
};

export const GUS_MESSAGES = {
    success: __('Your data has been downloaded from the GUS database.'),
    error: __('Failed to download data from GUS. Complete your company details below.')
};
