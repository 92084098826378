/**
 * SatisflyMagefan compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { DeviceType } from 'Type/Device.type';

import BlogCategories from '../BlogCategories';
import BlogRecentPosts from '../BlogRecentPosts';
import BlogSearchBar from '../BlogSearchBar';
import BlogTags from '../BlogTags';

// import BlogTags from '../BlogTags';
import './BlogSidebar.style';

/** @namespace Satisfly/MagefanBlog/Component/BlogSidebar/Component */
export class BlogSidebarComponent extends PureComponent {
    static propTypes = {
        posts: PropTypes.shape({
            // eslint-disable-next-line react/forbid-prop-types
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        isCategoryOrTagOverlayOpen: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
        closeOverlay: PropTypes.func.isRequired
    };

    renderBlogCategoriesIfNotMobile() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return <BlogCategories />;
    }

    renderMobileBlogCategories() {
        const {
            device, isCategoryOrTagOverlayOpen, closeOverlay
        } = this.props;

        if (device.isMobile) {
            return (
                <div className="BlogHeaderWrapper">
                    <BlogCategories
                      isCategoryOrTagOverlayOpen={ isCategoryOrTagOverlayOpen }
                      closeOverlay={ closeOverlay }
                    />
                </div>
            );
        }

        return null;
    }

    renderBlogTagsIfNotMobile() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return <BlogTags />;
    }

    renderMobileBlogTags() {
        const {
            device, isCategoryOrTagOverlayOpen, closeOverlay
        } = this.props;

        if (device.isMobile) {
            return (
                <div className="BlogHeaderWrapper">
                    <BlogTags
                      isCategoryOrTagOverlayOpen={ isCategoryOrTagOverlayOpen }
                      closeOverlay={ closeOverlay }
                    />
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <aside block="BlogSidebar">
                <BlogRecentPosts dynamicTitle={ __('Recent Posts') } isSidebar />
            </aside>
        );
    }
}

export default BlogSidebarComponent;
